import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 142.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,142.000000) scale(0.100000,-0.100000)">
         <path d="M771 1109 c-71 -14 -181 -71 -240 -125 l-54 -50 191 -147 c105 -81
192 -147 194 -147 2 0 0 84 -3 187 -4 104 -7 212 -8 241 -1 58 0 57 -80 41z"/>
<path d="M1045 1078 c-4 -13 -66 -221 -109 -366 -15 -51 -20 -82 -13 -79 7 3
91 38 187 79 96 41 190 80 208 87 17 8 32 18 32 23 0 5 -11 29 -25 52 -36 62
-132 149 -203 185 -70 35 -71 36 -77 19z"/>
<path d="M1087 647 c-116 -48 -145 -63 -135 -72 13 -12 264 -145 274 -145 7 0
25 51 35 105 10 49 -5 176 -20 174 -3 0 -73 -28 -154 -62z"/>
<path d="M910 545 c0 -16 61 -329 65 -333 10 -10 105 32 146 65 44 36 97 100
88 107 -3 2 -208 117 -291 163 -5 2 -8 1 -8 -2z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
